const Bugsnag = {
  /**
   * Start Bugsnag. Must be called before any other Bugsnag methods.
   * @param params
   * @param params.metadata Initial metadata to send with all captured events.
   */
  start: (_params: {metadata: any}) => {
    // Do nothing. We no longer care about Bugsnag notifications in the deprecated bundle.
  },

  /**
   * Add a breadcrumb to the stack. Breadcrumbs are sent to Bugsnag when an error is sent, providing additional
   * data on what happened prior to the error.
   * @param {string} _name The name of the breadcrumb.
   * @param {any} _metaData Any additional data to send with the breadcrumb.
   * @param {BreadcrumbType} _type The type of breadcrumb (i.e. "navigation" or "request"). See BreadcrumbType for options.
   */
  leaveBreadcrumb: (_name: string, _metaData: any, _type: any) => {
    // Do nothing. We no longer care about Bugsnag notifications in the deprecated bundle.
  },

  /**
   * Send a notification to Bugsnag.
   * @param {Error} _error The error to log.
   * @param {BeforeNotifyCallback} onBeforeNotify Optional callback allows modifying the event before it is sent to Bugsnag.
   * Use to set `severity` or add metadata with the `addMetadata` function call. If callback returns false, the event will
   * not be sent. Should behave similar to the `onError` callback of the official Bugsnag client:
   * https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
   * @param _options
   */
  notify: (_error: Error, _options?: any) => {
    // Do nothing. We no longer care about Bugsnag notifications in the deprecated bundle.
  },
};

export default Bugsnag;
